<template>
  <div class="form-slot">
    <b-row
      class="mx-0"
      align-h="between"
    >
      <p class="slot-title">
        {{ title }}
        <b-icon
          v-if="Boolean(guideId)"
          :id="guideId"
          icon="info-circle"
        />
      </p>
      <div class="slot-title-suffix">
        <slot name="title-suffix" />
      </div>
    </b-row>
    <slot />
  </div>
</template>

<script>
import { BIcon, BRow } from 'bootstrap-vue'

export default {
  components: {
    BIcon,
    BRow,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    guideId: {
      type: String,
      default: null,
    },
  },
}
</script>
<style lang="scss">
.form-slot {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  .slot-title {
    font-size: large;
    margin-bottom: 20px;
  }
  .slot-title-suffix {
    margin-bottom: 20px;
  }
}
</style>

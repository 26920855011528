<template>
  <form-slot-layout title="캠페인 기간">
    <b-row align-h="center">
      <b-col cols="6">
        <div
          class="d-flex align-items-center"
        >
          <span class="text-nowrap mr-1">
            시작
          </span>
          <flat-pickr
            v-model="start"
            :config="configStart"
            :disabled="readOnly"
            class="form-control"
          />
        </div>
      </b-col>
      <b-col cols="6">
        <div
          class="d-flex align-items-center"
        >
          <span class="text-nowrap mr-1">
            종료
          </span>
          <flat-pickr
            v-model="end"
            :config="configEnd"
            :disabled="readOnly"
            class="form-control"
          />
        </div>
      </b-col>
    </b-row>
    <small
      v-show="!isDateValid"
      class="text-danger"
    >
      시작/종료일을 설정해 주세요.
    </small>
  </form-slot-layout>

</template>

<script>
import {
  BCol, BRow,
} from 'bootstrap-vue'
import {
  computed,
  inject,
} from '@vue/composition-api'
import { Korean } from 'flatpickr/dist/l10n/ko'
import flatPickr from 'vue-flatpickr-component'
import FormSlotLayout from '@/views/layouts/FormSlotLayout.vue'

export default {
  components: {
    FormSlotLayout,
    flatPickr,
    BCol,
    BRow,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { start, end } = inject('currentCampaign')
    const configStart = computed(() => ({
      wrap: false,
      mode: 'single',
      locale: Korean,
      disable: [
        function (date) {
          // return true to disable
          const today = new Date(Date.parse(start.value))
          return date < new Date(today.getFullYear(), today.getMonth(), today.getDate())
        },
      ],
    }))

    const configEnd = computed(() => ({
      wrap: false,
      mode: 'single',
      locale: Korean,
      disable: [
        function (date) {
          // return true to disable
          return date < new Date(Date.parse(start.value))
        },
      ],
    }))

    return {
      start,
      end,
      configStart,
      configEnd,
      isDateValid: inject('isDateValid'),
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
.form-control:disabled {
  cursor: auto;
}
.date-prepend {
  margin-right: 15px;
}

.infinite-check {
  margin-top: 20px;
}
</style>

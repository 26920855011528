import { ref } from '@vue/composition-api'
import store from '@/store'

export default function useCampaignTask() {
  const loading = ref(false)
  const error = ref(null)

  const fetchCampaignList = async (queryString, start, end) => {
    loading.value = true
    const adAccountId = store.getters['account/accountId']
    return store.dispatch('campaign/fetchCampaignList', {
      adAccountId,
      params: {
        q: queryString ?? '',
        start,
        end,
      },
    }).then(response => {
      if (response.data.status === 2000) {
        return response.data.payload
      }
      throw response.data.message
    }).catch(e => {
      error.value = e
    }).finally(() => {
      loading.value = false
    })
  }

  const fetchCampaign = async id => {
    loading.value = true
    const adAccountId = store.getters['account/accountId']
    return store.dispatch('campaign/fetchCampaign', {
      adAccountId,
      campaignId: id,
    }).then(response => {
      if (response.data.status === 2000) {
        return response.data.payload
      }
      throw response.data.message
    }).catch(e => {
      error.value = e
    }).finally(() => {
      loading.value = false
    })
  }

  const fetchChannels = async () => {
    loading.value = true
    return store.dispatch('campaign/fetchChannels')
      .then(response => {
        const { channels } = response.data.payload
        return channels
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const uploadFile = async (campaign, content, file) => store.dispatch('campaign/uploadFile', { campaign, content, file })
  const deleteFile = async (campaign, content, file) => store.dispatch('campaign/deleteFile', { campaign, content, file })

  const createCampaign = campaign => {
    loading.value = true
    return store.dispatch('campaign/createCampaign', campaign)
      .then(response => {
        if (response.data.status === 2000) {
          return response.data.payload
        }
        throw response.data.message
      }).catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const updateCampaign = campaign => {
    loading.value = true
    return store.dispatch('campaign/updateCampaign', campaign)
      .then(response => {
        if (response.data.status === 2000) {
          return response.data.payload
        }
        throw response.data.message
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const updateStatus = async campaign => {
    loading.value = true
    return store.dispatch('campaign/updateCampaignStatus', campaign)
      .then(response => {
        if (response.data.status === 2000) {
          return response.data.payload
        }
        throw response.data.message
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const deleteCampaign = async campaign => {
    loading.value = true
    return store.dispatch('campaign/deleteCampaign', campaign)
      .then(response => {
        if (response.data.status === 2000) {
          return response.data.payload
        }
        throw response.data.message
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const requestReview = async campaign => {
    loading.value = true
    return store.dispatch('campaign/requestReview', campaign)
      .then(response => {
        if (response.data.status === 2000) {
          return response.data.payload
        }
        throw response.data.message
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const updateContents = async (campaign, content) => {
    loading.value = true
    if (content.imageFile) {
      await uploadFile(campaign, content, content.imageFile)
    }
    return store.dispatch('campaign/updateCampaignContent', { campaign, content })
      .then(response => {
        if (response.data.status === 2000) {
          return response.data.payload
        }
        throw response.data.message
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const createContent = async (campaign, content) => {
    loading.value = true
    if (content.imageFile) {
      await uploadFile(campaign, content, content.imageFile)
    }
    return store.dispatch('campaign/createCampaignContent', { campaign, content })
      .then(response => {
        if (response.data.status === 2000) {
          return response.data.payload
        }
        throw response.data.message
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const deleteContent = async (campaign, content) => {
    loading.value = true
    await deleteFile(campaign, content, content.imageFile)
    return store.dispatch('campaign/deleteCampaignContent', { campaign, content })
      .then(response => {
        if (response.data.status === 2000) {
          return response.data.payload
        }
        throw response.data.message
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  return {
    loading,
    error,
    fetchCampaignList,
    fetchCampaign,
    createCampaign,
    updateCampaign,
    updateContents,
    createContent,
    deleteContent,
    updateStatus,
    deleteCampaign,
    fetchChannels,
    requestReview,
  }
}

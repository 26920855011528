<template>
  <form-slot-layout title="도착 URL">
    <validation-provider
      #default="{ errors }"
      name="URL"
      rules="required|url"
    >
      <b-form-input
        id="input-landing-url"
        v-model="landingUrl"
        placeholder="http://website.com"
        size="lg"
        :disabled="readOnly"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>

  </form-slot-layout>
</template>

<script>
import {
  BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { required, url } from '@validations'
import { inject } from '@vue/composition-api'
import FormSlotLayout from '@/views/layouts/FormSlotLayout.vue'

export default {
  components: {
    FormSlotLayout,
    ValidationProvider,
    BFormInput,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { landingUrl } = inject('currentCampaign')
    return {
      landingUrl,
      required,
      url,
    }
  },
}
</script>

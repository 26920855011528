<template>
  <form-slot-layout
    title="일예산"
    guide-id="popover-cost"
  >
    <guide-popover
      target="popover-cost"
      title="일예산"
    >
      광고주님이 해당 캠페인을 위해 하루 최대 사용할 광고 집행 비용을 적어주세요.<br><br>
      기입하신 일일금액을 초과할시, 그 날의 광고는 더이상 집행되지 않습니다.
    </guide-popover>
    <validation-provider
      #default="{ errors }"
      name="예산"
      rules="required|size"
    >
      <b-input-group
        append="KRW"
        class="input-group-merge"
      >
        <b-form-input
          id="cost-input"
          v-model="dailyCost"
          style="text-align: end"
          type="number"
          size="lg"
          placeholder="0"
          :disabled="readOnly"
        />
      </b-input-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>

  </form-slot-layout>
</template>

<script>
import {
  BInputGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { inject } from '@vue/composition-api'
import FormSlotLayout from '@/views/layouts/FormSlotLayout.vue'

export default {
  components: {
    FormSlotLayout,
    BInputGroup,
    BFormInput,
    ValidationProvider,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { dailyCost } = inject('currentCampaign')

    return {
      dailyCost,
      required,
    }
  },
}
</script>

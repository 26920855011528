<template>
  <b-card-body>
    <div class="status-header">
      <p
        v-if="showingStatus.icon"
        class="status-label"
      >
        캠페인 상태 :
        <b-icon
          v-if="showingStatus.icon"
          class="status-icon"
          scale="1.5"
          :icon="showingStatus.icon"
          :variant="showingStatus.variant"
        />
      </p>
      <p
        v-else
        class="status-label"
      >
        캠페인 상태 : {{ showingStatus.text }}
      </p>
    </div>
    <div
      v-if="hasRejectHistory"
      class="reject-commentary"
    >
      <b-form-group
        label-size="lg"
        label="반려사유"
        label-for="reject-commentary"
      >
        <b-form-textarea
          id="reject-commentary"
          v-model="rejectHistories[0].comment"
          class="text-danger"
          rows="4"
          :state="false"
          plaintext
        />
      </b-form-group>
    </div>
    <div
      v-if="isActivated"
      class="d-flex justify-content-end"
    >
      <span class="pause-info">
        캠페인 집행을 중지하고 싶으시다면?
        <b-icon
          id="popover-pause"
          icon="info-circle"
        />
      </span>
      <guide-popover
        target="popover-pause"
        title="캠페인 집행 중지 가이드"
      >
        캠페인 집행을 완전히 중지하시려면 집행 기간을 원하시는 종료일로 수정해주세요.<br><br>
        *단 운영중인 캠페인 일정 조정과 원활한 반영을 위해 현재 날짜(금일)는 선택하실 수 없습니다.
      </guide-popover>
    </div>
  </b-card-body>
</template>

<script>
import {
  BIcon, BFormGroup, BFormTextarea, BCardBody,
  VBHover,
} from 'bootstrap-vue'
import {
  computed, inject,
} from '@vue/composition-api'
import useStatusResolver from '@/views/utils/useStatusResolver'

export default {
  components: {
    BCardBody,
    BIcon,
    BFormGroup,
    BFormTextarea,
  },
  directives: {
    'b-hover': VBHover,
  },
  setup() {
    const { resolveCampaignStatus, resolveReviewStatus } = useStatusResolver()
    const { status, reviewStatus, rejectHistories } = inject('currentCampaign')

    const isActivated = computed(() => reviewStatus.value === 'ACCEPTED' && status.value === 'ACTIVATED')
    const showingStatus = computed(() => {
      if (reviewStatus.value === 'ACCEPTED') {
        return resolveCampaignStatus(status.value)
      }
      return resolveReviewStatus(reviewStatus.value)
    })

    const hasRejectHistory = computed(() => rejectHistories.value.length > 0 && reviewStatus.value === 'REJECTED')
    return {
      showingStatus,
      hasRejectHistory,
      rejectHistories,
      isActivated,
    }
  },
}
</script>

<style lang="scss">
.status-header {
  display: flex;
  flex-direction: row;

  .status-label {
    font-size: 20px;
    color: white;
    margin-left: auto;
  }

  .status-icon {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.pause-info {
  margin-right: 20px;
}
</style>

<template>
  <loading-component
    v-if="loading"
  />
  <error-component
    v-else-if="error"
    :error="error"
  />
  <validation-observer
    v-else
    ref="refForm"
  >
    <app-form-layout>
      <template
        v-if="!isRegister"
        #top
      >
        <campaign-status />
      </template>

      <template #left>
        <name-form
          type="캠페인"
          inject-key="currentCampaign"
          :read-only="isReadOnly"
        />
        <campaign-date-selector :read-only="isReadOnly" />
        <button-selector-form
          title="캠페인 유형"
          data-key="currentCampaign"
          model-key="chargeType"
          option-key="chargeOptions"
          guide-id="popover-charge-type"
          :read-only="isReadOnly"
        />
        <guide-popover
          target="popover-charge-type"
          title="캠페인 유형"
        >
          CPM : 고객에게 노출되는 만큼 과금됩니다. 브랜드 인지도를 높이고 싶은 광고주님께 적합한 캠페인 유형입니다.<br><br>
          CPC : 고객이 해당 캠페인을 클릭하여 방문했을 때 과금됩니다. 매출을 향상하거나 고객의 특정 행동을 유도할때 추천드립니다.
        </guide-popover>
      </template>

      <template #right>
        <campaign-daily-cost :read-only="isReadOnly" />
        <button-selector-form
          title="레이아웃"
          data-key="currentCampaign"
          model-key="layout"
          option-key="layoutOptions"
          guide-id="popover-layout"
          :read-only="isReadOnly"
        />
        <guide-popover
          target="popover-layout"
          title="레이아웃"
        >
          채널별로 지원하는 레이아웃에 차이가 있을 수 있습니다.<br>
          이미지 광고 : 광고주님이 업로드 하신 이미지가 광고로 노출됩니다.<br>
          동영상 광고 : 동영상을 지원하는 광고 구좌에서 광고주님이 업로드 하신 영상을 통해 광고가 노출됩니다.<br>
          검색 광고 : 채널의 검색창에서 관련 키워드 검색시 추천 URL로 노출됩니다.<br>
        </guide-popover>
        <campaign-landing-url :read-only="isReadOnly" />
      </template>

      <template #middle>
        <campaign-contents :read-only="isReadOnly" />
        <campaign-contents-list />
      </template>

      <template
        v-if="isRegister && !isReadOnly"
        #submit-button
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mb-2 mt-4"
          block
          @click="onCreate"
        >
          캠페인 생성하기
        </b-button>
      </template>

      <template
        v-else-if="!isReadOnly"
        #submit-button
      >
        <b-button
          v-if="isAccepted"
          v-b-modal.modal-alert-update
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mb-2 mt-2"
          block
          :disabled="!hasChange"
        >
          캠페인 수정하기
        </b-button>
        <b-button
          v-else
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mb-2 mt-2"
          block
          :disabled="!hasChange"
          @click="onUpdate"
        >
          캠페인 수정하기
        </b-button>
        <b-button
          v-if="isRejected"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mb-2 mt-2"
          block
          @click="onRequireRevision"
        >
          재심사 요청하기
        </b-button>
        <b-button
          v-if="!isAccepted"
          v-b-modal.modal-alert-delete
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="mb-2 mt-2"
          block
        >
          캠페인 삭제하기
        </b-button>

      </template>
    </app-form-layout>

    <!-- modal update alert -->
    <b-modal
      id="modal-alert-update"
      title="현재 캠페인을 수정하시겠습니까?"
      ok-title="수정하기"
      cancel-title="취소"
      ok-variant="primary"
      cancel-variant="outline-secondary"
      hide-header-close
      centered
      @ok="onUpdate"
    >
      <b-card-text>
        승인된 캠페인의 기간/유형/일 예산을 제외한 나머지 항목을 수정하실 경우<br>
        프로젝트X 담당자의 재심사와 승인이 필요합니다.<br>
        그래도 진행하시겠습니까?
      </b-card-text>
    </b-modal>

    <!-- modal delete alert -->
    <b-modal
      id="modal-alert-delete"
      title="현재 캠페인을 삭제하시겠습니까?"
      ok-title="삭제"
      cancel-title="취소"
      ok-variant="primary"
      cancel-variant="outline-secondary"
      centered
      hide-header-close
      @ok="onDelete"
    >
      <b-card-text>삭제한 데이터는 복원할 수 없습니다.</b-card-text>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import {
  BButton, BCardText,
} from 'bootstrap-vue'
import useCampaign from '@/views/utils/campaign/useCampaign'
import useCampaignTask from '@/views/utils/task/useCampaignTask'
import { canWriteCampaign } from '@/libs/acl/protection'
import { computed, onMounted } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import AppFormLayout from '@/views/layouts/AppFormLayout.vue'
import NameForm from '@/views/components/NameForm.vue'
import ButtonSelectorForm from '@/views/components/ButtonSelectorForm.vue'
import CampaignContentsList from '@/views/components/campaign/CampaignContentsList.vue'
import CampaignStatus from '@/views/components/campaign/CampaignStatus.vue'
import CampaignDateSelector from '../../../components/campaign/CampaignDateSelector.vue'
import CampaignDailyCost from '../../../components/campaign/CampaignDailyCost.vue'
import CampaignLandingUrl from '../../../components/campaign/CampaignLandingUrl.vue'
import CampaignContents from '../../../components/campaign/CampaignContents.vue'

export default {
  components: {
    CampaignStatus,
    CampaignContentsList,
    ValidationObserver,
    AppFormLayout,
    NameForm,
    ButtonSelectorForm,
    BButton,
    BCardText,
    CampaignDateSelector,
    CampaignDailyCost,
    CampaignLandingUrl,
    CampaignContents,
  },
  directives: {
    Ripple,
  },
  props: {
    isRegister: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      loading,
      error,
      fetchCampaign,
      createCampaign,
      updateCampaign,
      updateContents,
      createContent,
      deleteContent,
      deleteCampaign,
      requestReview,
    } = useCampaignTask()
    const {
      refForm,
      currentCampaign,
      hasChange,
      validate,
      setCurrentCampaign,
      makeCampaignData,
    } = useCampaign()
    const { route, router } = useRouter()
    const { reviewStatus, status } = currentCampaign
    const isAccepted = computed(() => reviewStatus.value === 'ACCEPTED')
    const isRejected = computed(() => reviewStatus.value === 'REJECTED')
    const isReadOnly = computed(() => status.value === 'CLOSED' || !canWriteCampaign())

    const onUpdate = async () => {
      if (validate()) {
        reviewStatus.value = 'PENDING'
        const campaign = makeCampaignData()
        const { campaignContents } = campaign
        const promises = []
        campaignContents.forEach(content => {
          if (content.id) {
            // existing contents
            if (content.imageFile) {
              // update campaign contents with new image file
              promises.push(updateContents(campaign, content))
            } else if (!content.filename) {
              // delete campaign contents if existing filename has removed
              promises.push(deleteContent(campaign, content))
            }
          } else if (content.imageFile) {
            // new contents
            promises.push(createContent(campaign, content))
          }
        })
        promises.push(updateCampaign(campaign))
        const result = await Promise.all(promises)
        setCurrentCampaign(result[result.length - 1])
      }
    }

    const onDelete = async () => {
      const campaign = makeCampaignData()
      await deleteCampaign(campaign)
      router.go(-1)
    }

    const onRequireRevision = async () => {
      if (validate()) {
        const campaign = makeCampaignData()
        const newCampaign = await requestReview(campaign)
        setCurrentCampaign(newCampaign)
      }
    }

    const onCreate = async () => {
      if (validate()) {
        const campaign = makeCampaignData()
        const { campaignContents } = campaign
        const promises = []
        campaignContents.forEach(content => {
          if (content.imageFile) {
            // new contents
            promises.push(createContent(campaign, content))
          }
        })
        promises.push(createCampaign(campaign))
        Promise.all(promises).then(() => router.go(-1))
      } else {
        window.scrollTo(0, 0)
      }
    }

    onMounted(async () => {
      if (!props.isRegister) {
        const { campaignId } = route.value.params
        const campaign = await fetchCampaign(campaignId)
        setCurrentCampaign(campaign)
      }
    })

    return {
      loading,
      error,
      refForm,
      isAccepted,
      isRejected,
      isReadOnly,
      hasChange,
      onCreate,
      onUpdate,
      onDelete,
      onRequireRevision,
    }
  },
}
</script>
<style lang="scss">
.modal-header {
  border-bottom: 0 none;
}

.modal-footer {
  border-top: 0 none;
}
</style>

import {
  ref, reactive, toRefs, provide, readonly, watchEffect, computed,
} from '@vue/composition-api'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useDate from '@/views/utils/useDate'
import store from '@/store'

export default function useCampaign() {
  const { t } = useI18nUtils()
  const refForm = ref(null)
  const cachedCampaign = ref(null)
  const { todayString, parseDateToString, isClosed } = useDate()

  const currentCampaign = toRefs(reactive({
    id: null,
    adAccountId: store.getters['account/accountId'],
    name: '',
    chargeType: 'CPM',
    dailyCost: 0,
    layout: 'IMAGE',
    start: todayString(),
    end: null,
    landingUrl: null,
    reviewStatus: 'PENDING',
    status: 'ACTIVATED',
    campaignContents: [],
    rejectHistories: [],
  }))

  const makeCampaignData = () => {
    const data = {
      id: currentCampaign.id.value,
      adAccountId: currentCampaign.adAccountId.value,
      name: currentCampaign.name.value,
      chargeType: currentCampaign.chargeType.value,
      dailyCost: currentCampaign.dailyCost.value,
      layout: currentCampaign.layout.value,
      start: Date.parse(currentCampaign.start.value),
      end: Date.parse(currentCampaign.end.value),
      landingUrl: currentCampaign.landingUrl.value,
      reviewStatus: currentCampaign.reviewStatus.value,
      status: currentCampaign.status.value,
      campaignContents: currentCampaign.campaignContents.value,
    }
    return data
  }

  const hasChange = ref(false)
  const hasValidatedOnce = ref(false)
  const isDateValid = computed(() => {
    if (!hasValidatedOnce.value) {
      return true
    }
    return (currentCampaign.start.value && currentCampaign.end.value)
  })

  const setCurrentCampaign = campaign => {
    currentCampaign.id.value = campaign.id
    currentCampaign.adAccountId.value = campaign.adAccountId
    currentCampaign.name.value = campaign.name
    currentCampaign.chargeType.value = campaign.chargeType
    currentCampaign.dailyCost.value = campaign.dailyCost
    currentCampaign.layout.value = campaign.layout
    currentCampaign.start.value = parseDateToString(campaign.start)
    currentCampaign.end.value = parseDateToString(campaign.end)
    currentCampaign.landingUrl.value = campaign.landingUrl
    currentCampaign.reviewStatus.value = campaign.reviewStatus
    currentCampaign.status.value = isClosed(campaign.end) && campaign.reviewStatus === 'ACCEPTED' ? 'CLOSED' : campaign.status
    currentCampaign.campaignContents.value = campaign.campaignContents
    currentCampaign.rejectHistories.value = campaign.rejectHistories

    cachedCampaign.value = JSON.stringify({
      id: currentCampaign.id.value,
      adAccountId: currentCampaign.adAccountId.value,
      name: currentCampaign.name.value,
      chargeType: currentCampaign.chargeType.value,
      dailyCost: currentCampaign.dailyCost.value,
      layout: currentCampaign.layout.value,
      start: currentCampaign.start.value,
      end: currentCampaign.end.value,
      landingUrl: currentCampaign.landingUrl.value,
      reviewStatus: currentCampaign.reviewStatus.value,
      campaignContents: currentCampaign.campaignContents.value,
      status: currentCampaign.status.value,
    })
    hasChange.value = false
  }

  const validate = () => {
    hasValidatedOnce.value = true
    return refForm.value.validate() && isDateValid.value
  }

  watchEffect(() => {
    hasChange.value = cachedCampaign.value !== JSON.stringify({
      id: currentCampaign.id.value,
      adAccountId: currentCampaign.adAccountId.value,
      name: currentCampaign.name.value,
      chargeType: currentCampaign.chargeType.value,
      dailyCost: currentCampaign.dailyCost.value,
      layout: currentCampaign.layout.value,
      start: currentCampaign.start.value,
      end: currentCampaign.end.value,
      landingUrl: currentCampaign.landingUrl.value,
      reviewStatus: currentCampaign.reviewStatus.value,
      campaignContents: currentCampaign.campaignContents.value,
      status: currentCampaign.status.value,
    })
  })

  const chargeOptions = [
    { text: 'CPM', value: 'CPM' },
    { text: 'CPC', value: 'CPC' },
  ]

  const layoutOptions = [
    { text: t('IMAGE'), value: 'IMAGE' },
    { text: t('VIDEO'), value: 'VIDEO' },
    { text: t('SEARCH'), value: 'SEARCH' },
  ]

  // *===============================================---*
  // *--------- Provide --------------------------------*
  // *===============================================---*
  provide('currentCampaign', currentCampaign)

  // options
  provide('layoutOptions', readonly(layoutOptions))
  provide('chargeOptions', readonly(chargeOptions))
  provide('isDateValid', isDateValid)

  return {
    refForm,
    validate,
    hasChange,
    currentCampaign,
    setCurrentCampaign,
    makeCampaignData,
  }
}

<template>
  <form-slot-layout
    :title="title"
    :guide-id="guideId"
  >
    <b-form-radio-group
      v-model="model"
      class="d-flex flex-wrap"
      :options="options"
      name="radios-btn-default"
      button-variant="primary"
      :disabled="readOnly"
      buttons
    />
  </form-slot-layout>
</template>

<script>
import {
  BFormRadioGroup,
} from 'bootstrap-vue'
import { inject } from '@vue/composition-api'
import FormSlotLayout from '@/views/layouts/FormSlotLayout.vue'

export default {
  components: {
    BFormRadioGroup,
    FormSlotLayout,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    optionKey: {
      type: String,
      required: true,
    },
    dataKey: {
      type: String,
      required: true,
    },
    modelKey: {
      type: String,
      required: true,
    },
    guideId: {
      type: String,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const data = inject(props.dataKey)
    return {
      model: data[props.modelKey],
      options: inject(props.optionKey),
    }
  },
}
</script>

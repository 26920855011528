<template>
  <b-card class="form-layout-wrapper">
    <slot name="top"></slot>
    <b-row>
      <!-- Left Col -->
      <b-col
        class="border-right"
        cols="6"
      >
        <div class="form-column">
          <slot name="left"></slot>
        </div>
      </b-col>

      <!-- Right Col -->
      <b-col
        cols="6"
      >
        <div class="form-column">
          <slot name="right"></slot>
        </div>
      </b-col>
    </b-row>
    <div class="my-2">
      <slot name="middle"></slot>
    </div>
    <b-row align-h="center">
      <b-col cols="4">
        <div class="submit-btn">
          <slot name="submit-button"></slot>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCol, BRow } from 'bootstrap-vue'
import useLocaleValidation from '@/views/utils/useLocaleValidation'

export default {
  components: {
    BCard,
    BCol,
    BRow,
  },
  setup() {
    useLocaleValidation()
  },
}
</script>

<style lang="scss">
.form-layout-wrapper {
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;

  .form-column {
    padding-left: 20px;
    padding-right: 20px;
  }
  .submit-btn {
    margin-top: 30px;
    margin-bottom: 50px;
  }
}
</style>

<template>
  <b-tabs card>
    <b-tab
      v-for="channel in channelOptions"
      :key="channel.id"
      class="overflow-scroll"
    >
      <template #title>
        <b class="tab-title">{{ channel.name }}</b>
      </template>
      <div class="sub-title">
        <b>광고 구좌 선택</b>
        <b-form-radio-group
          v-model="device"
          class="mx-2"
          size="sm"
          :options="deviceOptions"
          name="radios-btn-default"
          button-variant="primary"
          buttons
          :disabled="readOnly"
        />
      </div>
      <b-card-text class="mb-3">
        광고를 원하시는 구좌를 클릭하여 해당 구좌 사이즈/파일 형식/파일 크기 가이드에 맞게 이미지를 업로드 해주세요.  파일을 업로드하시면 미리보기를 통해 광고 집행시 모습을 확인하실 수 있습니다.
      </b-card-text>
      <app-collapse>
        <app-collapse-item
          v-for="(slot, index) in channel.slots"
          :key="index"
          title=""
          :is-visible="expandAll || Boolean(slot.src)"
        >
          <template #header>
            <div class="sub-title">
              <p>
                <b class="mr-1">{{ `${index+1}. ${slot.name}` }}</b>
                <b-icon
                  :id="`popover-slot-${slot.id}`"
                  icon="info-circle"
                />
              </p>
              <guide-popover
                :target="`popover-slot-${slot.id}`"
                :title="`${slot.name} 가이드`"
              >
                {{ slot.description }}
              </guide-popover>
            </div>
          </template>
          <div
            class="slot-content-container"
            :style="{
              height: `${slot.meta.backgroundImageSize.height}px`,
              width: `${slot.meta.backgroundImageSize.width}px`,
            }"
          >
            <content-uploader
              :id="slot.id.toString()"
              :img-src="slot.src"
              :size="slot.meta.size"
              :position="slot.meta.position"
              :read-only="readOnly"
              @change="onFileChange"
            >
              <div class="upload-content">
                <feather-icon
                  icon="UploadIcon"
                  size="50"
                />
                <h3 class="mt-1">
                  {{ slot.name }}
                </h3>
              </div>
            </content-uploader>
          </div>
        </app-collapse-item>
      </app-collapse>
    </b-tab>
  </b-tabs>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BTab, BFormRadioGroup, BCardText, BIcon, BTabs,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import useLocaleValidation from '@/views/utils/useLocaleValidation'
import { onMounted, ref } from '@vue/composition-api'
import useCampaignTask from '@/views/utils/task/useCampaignTask'
import useCampaignContents from '@/views/utils/campaign/useCampaignContents'
import ContentUploader from '@/views/components/campaign/ContentUploader.vue'
import { adRouteNames } from '@/router/routes/ad'

export default {
  components: {
    ContentUploader,
    BTabs,
    BTab,
    BFormRadioGroup,
    BCardText,
    BIcon,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    Ripple,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    expandAll: {
      get() {
        return this.$route.name === adRouteNames.registerCampaign
      },
    },
  },
  setup() {
    useLocaleValidation()
    const { fetchChannels } = useCampaignTask()
    const {
      setChannels,
      channelOptions,
      updateContents,
    } = useCampaignContents()

    onMounted(async () => {
      const channels = await fetchChannels()
      setChannels(channels)
    })
    const device = ref('PC')
    const deviceOptions = [
      { text: 'PC', value: 'PC' },
      { text: 'Mobile', value: 'Mobile', disabled: true },
    ]
    const onFileChange = content => {
      updateContents(content)
    }

    return {
      channelOptions,
      device,
      deviceOptions,
      onFileChange,
    }
  },
}
</script>

<style lang="scss" scoped>
.tab-title {
  font-size: 20px;
}
.sub-title {
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.slot-content-container {
  position: relative;
}
.slot-content-container::before{
  content: "";
  background-image: url('https://placekitten.com/1200/800');
  background-size: cover;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.upload-content {
  position: relative;
  top: calc(50% - 50px);
}
</style>

<template>
  <div>
    <div
      class="slot-content text-center"
      :class="{'border-primary': Boolean(source)}"
      :style="style"
      @mouseenter="isHovering=true"
      @mouseleave="isHovering=false"
      @click="showModal"
    >
      <b-button
        v-if="!readOnly && Boolean(source) && isHovering"
        class="rm-btn"
        pill
        size="sm"
        variant="danger"
        @click.stop="deleteImage"
      >
        <b-icon
          icon="x"
        />
        삭제
      </b-button>
      <slot v-if="!Boolean(source)" />
    </div>
    <!-- modal upload content -->
    <b-modal
      :id="id"
      title="가이드에 맞는 파일을 첨부해주세요."
      ok-title="적용하기"
      cancel-title="닫기"
      ok-variant="primary"
      cancel-variant="outline-secondary"
      hide-header-close
      centered
      @ok="handleOk"
    >
      <b-card-text class="my-2 line-height-3">
        <ul>
          <li>이미지 권장사양: {{ size.width }}* {{ size.height }} pixel</li>
          <li>파일 형식: JPEG, PNG</li>
          <li>파일 크기: 30MB 이내 {{ `(${fileSize} MB)` }}</li>
        </ul>
      </b-card-text>
      <form
        ref="form"
        @submit.stop.prevent="handleOk"
      >
        <b-form-group
          class="mb-2"
          :state="fileState"
          label-for="file-input"
          :invalid-feedback="invalidFeedback"
        >
          <b-form-file
            id="file-input"
            v-model="imageFile"
            :state="fileState"
            required
            accept="image/jpeg, image/png"
            browse-text="불러오기"
            placeholder="파일 첨부"
          />
        </b-form-group>
        <b-form-input
          id="text-input"
          v-model="subsText"
          class="mb-2"
          placeholder="이미지 대체 텍스트가 있다면 입력해주세요."
        />
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BIcon, BFormGroup,
  BFormInput, BFormFile, BCardText,
} from 'bootstrap-vue'

export default {
  name: 'ImagePreview',
  components: {
    BButton,
    BIcon,
    BFormGroup,
    BFormInput,
    BFormFile,
    BCardText,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    imgSrc: {
      type: String,
      default: null,
    },
    size: {
      type: Object,
      default: () => ({
        height: 200,
        width: 200,
      }),
    },
    position: {
      type: Object,
      default: () => ({
        top: 0,
        left: 0,
      }),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      source: this.imgSrc,
      imageFile: null,
      subsText: '',
      invalidFeedback: '',
      isHovering: false,
    }
  },
  computed: {
    style() {
      return {
        height: `${this.size.height}px`,
        width: `${this.size.width}px`,
        top: `${this.position.top}px`,
        left: `${this.position.left}px`,
        backgroundImage: this.source ? `url(${this.source})` : null,
        cursor: this.source || this.readOnly ? 'auto' : 'pointer',
      }
    },
    fileSize() {
      if (this.imageFile) {
        const size = this.imageFile.size / 1024 / 1024 // in MiB
        return size.toFixed(2)
      }
      return ''
    },
    fileState() {
      if (this.invalidFeedback.length > 0) {
        return false
      }
      return null
    },
  },
  methods: {
    replaceSource(localFile) {
      const reader = new FileReader()
      reader.onload = e => {
        this.source = e.target.result
        this.emitChange()
      }
      reader.readAsDataURL(localFile)
    },
    deleteImage() {
      this.source = null
      this.imageFile = null
      this.subsText = null
      this.emitChange()
    },
    showModal() {
      if (!this.readOnly && !this.source) {
        this.$bvModal.show(this.id)
      }
    },
    emitChange() {
      this.$emit('change', {
        slotId: this.id,
        imageFile: this.imageFile,
        filename: this.imageFile?.name,
        subsText: this.subsText,
      })
    },
    checkFormValidity() {
      if (this.imageFile) {
        if (this.fileSize > 30) {
          // exceed 30MB
          this.invalidFeedback = `파일 크기가 너무 큽니다. ${this.fileSize}MB/30MB`
        } else {
          this.invalidFeedback = ''
        }
      } else {
        this.invalidFeedback = '파일을 첨부하지 않았습니다.'
      }
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      this.checkFormValidity()

      if (this.fileState !== false) {
        this.replaceSource(this.imageFile)
        this.$bvModal.hide(this.id)
      } else {
        bvModalEvt.preventDefault()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.slot-content {
  border-width: 5px !important;
  position: relative;
  background-color: grey;
  background-size: cover;
  background-repeat: no-repeat;
  .rm-btn {
    position: absolute;
    right: 0px;
  }
}
.modal-header {
  border-bottom: 0 none;
}

.modal-footer {
  border-top: 0 none;
}
</style>

import {
  ref, inject, computed,
} from '@vue/composition-api'

export default function useCampaignContents() {
  const cachedChannels = ref([])
  const { campaignContents } = inject('currentCampaign')

  const hasChange = ref(false)

  const setChannels = channels => {
    cachedChannels.value = channels
  }

  const channelOptions = computed(() => cachedChannels.value.map(channel => {
    const { slots, ...newChannel } = channel
    const updatedSlots = channel.slots.map(slot => {
      const index = campaignContents.value.findIndex(c => c.slotId.toString() === slot.id.toString())
      if (index !== -1) {
        return { ...slot, src: campaignContents.value[index].filename }
      }
      return slot
    })
    return { ...newChannel, slots: updatedSlots }
  }))

  const updateContents = contents => {
    const { slotId } = contents
    const index = campaignContents.value.findIndex(e => e.slotId === slotId)
    if (index === -1) {
      // add new contents
      campaignContents.value.push(contents)
    } else {
      // replace contents
      campaignContents.value.splice(index, 1, contents)
    }
  }

  return {
    channelOptions,
    setChannels,
    updateContents,
    hasChange,
  }
}

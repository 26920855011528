<template>
  <form-slot-layout title="광고 구좌 신청 내역">
    <b-card-text class="mt-1 mb-1">
      광고주님! 신청하신 광고 구좌 내역을 확인해주세요. 캠페인 생성 후에도  수정하실 수 있습니다.
    </b-card-text>
    <b-row
      v-for="content in campaignContents"
      :key="content.slotId"
      align-h="center"
      class="mb-1"
    >
      <b-col>
        <div
          class="d-flex align-items-center"
        >
          <strong class="text-nowrap mr-1">
            {{ content.slotId }}
          </strong>
          <span class="text-nowrap mr-1">
            {{ content.filename }}
          </span>
        </div>
      </b-col>
    </b-row>
    <small
      v-show="!hasContents"
      class="text-warning"
    >
      광고 구좌가 신청되지 않았습니다.
    </small>
  </form-slot-layout>

</template>

<script>
import {
  BCardText, BCol, BRow,
} from 'bootstrap-vue'
import { computed, inject } from '@vue/composition-api'
import FormSlotLayout from '@/views/layouts/FormSlotLayout.vue'

export default {
  components: {
    FormSlotLayout,
    BCardText,
    BCol,
    BRow,
  },
  setup() {
    const { campaignContents } = inject('currentCampaign')
    const hasContents = computed(() => campaignContents.value.filter(c => c.filename).length > 0)
    return {
      campaignContents,
      hasContents,
    }
  },
}
</script>

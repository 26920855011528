import { onMounted } from '@vue/composition-api'
import { extend, localize } from 'vee-validate'
import { validatorUrlValidator } from '@core/utils/validations/validators'
import ko from 'vee-validate/dist/locale/ko.json'

export default function useLocaleValidation() {
  onMounted(() => {
    localize('ko', ko)
  })

  extend('password', value => {
    // const re = new RegExp('(?=.*[@$!%*#?&~]){8,}$')
    const re = new RegExp('(?=.*[@$!%*#?&~])(?=.{8,}$)')
    const regex = re.test(value)
    if (regex) {
      return true
    }

    return '특수문자를 포함한 8자 이상의 비밀번호를 입력하세요.'
  })

  extend('url', value => {
    const protocol = RegExp('^http[s]?:\\/\\/(.*)')
    if (protocol.test(value)) {
      if (validatorUrlValidator(value)) {
        return true
      }
    }
    return '양식에 맞는 url 주소를 입력하세요.'
  })

  extend('price', value => {
    if (value > 0) {
      return true
    }
    return '가격은 0원 이상으로 설정해야 합니다.'
  })

  extend('size', value => {
    if (value > 0) {
      return true
    }
    return '0 이상으로 설정해야 합니다.'
  })

  extend('tax', value => {
    if (value.length >= 10) {
      return true
    }

    return '사업자 등록번호 10자리를 입력해주세요.'
  })

  extend('description', value => {
    if (value.length > 0 && value.length < 250) {
      return true
    }
    return '띄어쓰기 포함 최대 250자로 작성해주세요.'
  })

  extend('searchLength', value => {
    if (value > 0 && value < 100) {
      return true
    }
    return '1~99 자 이내로 설정해야 합니다.'
  })
}
